import { AnyAction } from 'redux'
import { put, call, select } from 'redux-saga/effects'
import { workerCheckAuthentication } from 'store/auth/sagas'
import {
  animalQuery,
  animalUpdate,
  animalCreate,
  breedQuery,
  coatQuery,
  genderQuery,
  disableAnimalMutation
} from 'services/animal'
import Exception from 'helpers/exception'
import { MainTypes } from 'store/main/types'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import _ from 'lodash'
import { Animal, Breed, Coat, Gender, AnimalTypes } from './types'
import { AnimalQueryFields, AnimalQueryParams } from 'services/animal/types'

export function* workerGetAnimal(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore
    const animals = yield call(animalQuery, credentials.token, params)

    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { animals: [...animals], animalLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'lot/sagas/workerGetAnimal', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: false } })
  }
}

export function* workerSetSelectedAnimal(action: AnyAction) {
  const store = yield select(s => s)
  const { animalId } = action.payload

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: true } })
    yield call(workerCheckAuthentication)

    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    let selectedAnimal: Animal | undefined

    if (animalId) {
      const params: AnimalQueryParams = { args: { id: animalId } }
      const animalList = yield call(animalQuery, credentials.token, params)

      const mainStore = store.main
      const { history } = mainStore
      if (animalList.length) selectedAnimal = animalList[0]
      else {
        history.goBack()
        throw new Exception('', 'animals/sagas/workerSetSelectedAnimal')
      }
    }
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedAnimal: selectedAnimal ? { ...selectedAnimal } : undefined,
        selectedBreed: selectedAnimal?.breed ? { ...selectedAnimal.breed } : undefined,
        selectedCoat: selectedAnimal?.coat ? { ...selectedAnimal.coat } : undefined,
        selectedGender: selectedAnimal?.gender ? { ...selectedAnimal.gender } : undefined,
        animalLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedAnimal', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: false } })
  }
}

export function* workerSetSelectedAnimalGroup(action: AnyAction) {
  const { animals } = action.payload

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalGroupLoading: true } })
    yield call(workerCheckAuthentication)

    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedAnimalGroup: animals ? { ...animals } : undefined,
        animalGroupLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedAnimalGroup', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalGroupLoading: false } })
  }
}

export function* workerSetSelectedAnimalFile(action: AnyAction) {
  const { animals } = action.payload
  const animalFile = animals

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: true } })
    yield call(workerCheckAuthentication)

    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedAnimalFile: animalFile ? { ...animalFile } : undefined,
        animalLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedAnimalFile', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: false } })
  }
}

export function* workerSaveAnimal(action: AnyAction) {
  const { args } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: true } })
    yield call(workerCheckAuthentication)

    const store = yield select(s => s)
    const authStore = store.auth
    const animalStore = store.animal

    let { animals } = animalStore
    const { animal } = args
    const { credentials } = authStore

    args.animal = animal

    for (let i = 0; i < animal.length; i++) {
      delete animal[i].breed
      delete animal[i].coat
      delete animal[i].gender
    }

    if (animal[0].id) {
      yield call(animalUpdate, credentials.token, args)
      for (let i = 0; i < animal.length; i++) {
        const index = _.findIndex(animals, (a: Animal) => a.id === animal[i].id)
        if (!animal[0].newLotId) {
          animals[index] = (yield call(animalQuery, credentials.token, { args: { id: animal[i].id } }))[0]
          animal[0] = animals[index]
        } else {
          animals.splice(index, 1)
        }
      }
    } else {
      yield call(animalCreate, credentials.token, args)
      const fields: AnimalQueryFields = {
        id: true,
        lotId: true,
        earring: true,
        tag: true,
        age: true,
        horn: true,
        initialWeight: true,
        finalWeight: true,
        active: true,
        endDate: true,
        note: true,
        sisbov: true,
        lastWeighingDate: true,
        ecc: true,
        gender: {
          id: true,
          code: true,
          description: true
        },
        coat: {
          id: true,
          code: true,
          description: true
        },
        breed: {
          id: true,
          code: true,
          description: true
        },
        handling: {
          ecc: true
        }
      }
      animals = yield call(animalQuery, credentials.token, { args: { lotId: args.lotId }, fields: fields })
      // for (let i = 0; i < animal.length; i++) {
      //   animal[i].id = animalsIds[i]
      //   animal[i].breed = (yield call(breedQuery, credentials.token, { args: { id: animal[i].breedId } }))[0]
      //   animal[i].coat = (yield call(coatQuery, credentials.token, { args: { id: animal[i].coatId } }))[0]
      //   animal[i].gender = (yield call(genderQuery, credentials.token, { args: { id: animal[i].genderId } }))[0]
      //   animals.push(animal[i])
      // }
    }
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { animals: [...animals], selectedAnimal: { ...animal[0] }, animalLoading: false }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'lot/sagas/workerSaveAnimal', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { animalLoading: false } })
  }
}

export function* workerGetBreed(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { breedLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const breeds = yield call(breedQuery, credentials.token, params)
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { breedLoading: false, breeds }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerGetBreed', error)
      console.log(ex.getMessage().getText())
    }
  }
}

export function* workerSetSelectedBreed(action: AnyAction) {
  const store = yield select(s => s)
  const { breedId } = action.payload

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { breedLoading: true } })
    yield call(workerCheckAuthentication)

    const animalStore = store.animal
    const { breeds } = animalStore
    let selectedBreed: Breed | undefined

    if (breedId) {
      const mainStore = store.main
      const { history } = mainStore
      const index = _.findIndex(breeds, (em: Breed) => em.id === breedId)
      if (index !== -1) selectedBreed = breeds[index]
      else {
        history.goBack()
        throw new Exception('', 'animals/sagas/workerSetSelectedBreed')
      }
    }
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedBreed: selectedBreed ? { ...selectedBreed } : undefined,
        breedLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedBreed', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { breedLoading: false } })
  }
}

export function* workerGetCoat(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { coatLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const coats = yield call(coatQuery, credentials.token, params)
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { coatLoading: false, coats }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerGetCoat', error)
      console.log(ex.getMessage().getText())
    }
  }
}

export function* workerSetSelectedCoat(action: AnyAction) {
  const store = yield select(s => s)
  const { coatId } = action.payload

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { coatLoading: true } })
    yield call(workerCheckAuthentication)

    const animalStore = store.animal
    const { coats } = animalStore
    let selectedCoat: Coat | undefined

    if (coatId) {
      const mainStore = store.main
      const { history } = mainStore
      const index = _.findIndex(coats, (em: Coat) => em.id === coatId)
      if (index !== -1) selectedCoat = coats[index]
      else {
        history.goBack()
        throw new Exception('', 'animals/sagas/workerSetSelectedCoat')
      }
    }
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedCoat: selectedCoat ? { ...selectedCoat } : undefined,
        coatLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedCoat', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { coatLoading: false } })
  }
}

export function* workerGetGender(action: AnyAction) {
  const { params } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { genderLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const genders = yield call(genderQuery, credentials.token, params)
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { genderLoading: false, genders }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerGetGender', error)
      console.log(ex.getMessage().getText())
    }
  }
}

export function* workerSetSelectedGender(action: AnyAction) {
  const store = yield select(s => s)
  const { genderId } = action.payload

  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { genderLoading: true } })
    yield call(workerCheckAuthentication)

    const animalStore = store.animal
    const { genders } = animalStore
    let selectedGender: Gender | undefined

    if (genderId) {
      const mainStore = store.main
      const { history } = mainStore
      const index = _.findIndex(genders, (em: Gender) => em.id === genderId)
      if (index !== -1) selectedGender = genders[index]
      else {
        history.goBack()
        throw new Exception('', 'animals/sagas/workerSetSelectedGender')
      }
    }
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: {
        selectedGender: selectedGender ? { ...selectedGender } : undefined,
        genderLoading: false
      }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerSetSelectedGender', error)
      console.log(ex.getMessage().getText())
    }
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { genderLoading: false } })
  }
}

export function* workerDisableAnimal(action: AnyAction) {
  const { ids } = action.payload
  try {
    yield put({ type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA, payload: { disableAnimalLoading: true } })

    yield call(workerCheckAuthentication)
    const authStore = yield select(s => s.auth)
    const { credentials } = authStore

    const disableAnimalsNumber = yield call(disableAnimalMutation, credentials.token, ids)
    yield put({
      type: AnimalTypes.REDUCER_FETCH_ANIMAL_DATA,
      payload: { disableAnimalLoading: false, disableAnimalsNumber }
    })
  } catch (error) {
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
      yield put({ type: MainTypes.REDUCER_TOGGLE_TOAST, payload: { message: message.text } })
    } else {
      const ex = new Exception(UNEXPECTED_ERROR_CODE, 'animal/sagas/workerDisableAnimal', error)
      console.log(ex.getMessage().getText())
    }
  }
}
