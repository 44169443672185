import { Grid } from '@material-ui/core'
import React from 'react'
import i18next from 'i18next'
import _ from 'lodash'
import moment from 'moment'

export const generalRender = (rowData: any, type: string) => {
  if (type === 'active') {
    return (
      <Grid className={`field ${rowData.active ? 'active' : 'inactive'}`}>
        {rowData.active ? i18next.t('default.active') : i18next.t('default.inactive')}
      </Grid>
    )
  }
  if (type === 'gender.description') {
    return <Grid>{rowData.gender?.description || i18next.t('table.not_registered')}</Grid>
  }
  if (type === 'coat.description') {
    return <Grid>{rowData.coat?.description || i18next.t('table.not_registered')}</Grid>
  }
  if (type === 'breed.description') {
    return <Grid>{rowData.breed?.description || i18next.t('table.not_registered')}</Grid>
  }
  if (type === 'endDate') {
    return (
      <Grid>
        {rowData[type] ? moment(rowData[type]).format('DD/MM/YYYY HH:mm:ss') : i18next.t('field.not_registered')}
      </Grid>
    )
  }
  if (type.includes('additionalInfo')) {
    for (let i = 0; i < rowData?.additionalInfo?.length; i++) {
      for (const key in rowData?.additionalInfo[i]?.info) {
        if (type.slice(14) === key) {
          return <Grid>{rowData?.additionalInfo[i].info[key] || i18next.t('table.not_registered')}</Grid>
        }
      }
    }
  }
  if (type === 'handling.ecc') {
    return <Grid>{rowData.handling?.ecc || i18next.t('table.not_registered')}</Grid>
  } else {
    return <Grid>{rowData[type] || i18next.t('table.not_registered')}</Grid>
  }
}

export const getTitle = (column: string) => {
  if (column.includes('additionalInfo')) {
    return column.slice(14)
  }
  switch (column) {
    case 'initialWeight':
      return i18next.t('field.initialWeight')
    case 'endDate':
      return i18next.t('field.endDate')
    case 'gender.description':
      return i18next.t('field.gender')
    case 'coat.description':
      return i18next.t('field.coat')
    case 'breed.description':
      return i18next.t('field.breed')
    case 'handling.ecc':
      return i18next.t('field.initialEcc')
    default:
      return i18next.t('field.' + column)
  }
}

export const verifyFileColumns = (file: any, columns: string[]) => {
  const fileKeys = []
  fileKeys.push(
    _.map(file, f => {
      f.replace(/\s/g, '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      return f
    })
  )

  const columnsKeys = []
  columnsKeys.push(
    _.map(columns, c => {
      c.replace(/\s/g, '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      return c
    })
  )

  return _.isEqual(columnsKeys, fileKeys)
}
