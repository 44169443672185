import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useRef, useState, useEffect } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import DatePicker from 'components/widgets/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import * as Yup from 'yup'
import _ from 'lodash'
import { getLot, setSelectedLot } from 'store/feedlot/actions'
import { saveAnimal } from 'store/animal/actions'
import { statusOptions } from 'data'
import { formatFormFields } from './helper'
import Select from 'components/widgets/Select'
import BreedSelector from 'components/selectors/Breed'
import CoatSelector from 'components/selectors/Coat'
import GenderSelector from 'components/selectors/Gender'
import { useTranslation } from 'react-i18next'
import LotSelector from 'components/selectors/Lot'
import ConfirmationModal from 'components/modals/ConfirmationModal'

const AnimalForm: React.FC<Props> = ({ feedlotId, lotId, handleClose }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { animals, selectedAnimal, animalLoading } = useSelector((state: ApplicationState) => state.animal)
  const [isConfirmationModalOpen, setConfirmationModalStatus] = useState<boolean>(false)
  const [isConfirmationModalDuplicatedTagOpen, setConfirmationModalDuplicatedTagStatus] = useState<boolean>(false)
  const [confirmationModalDuplicatedTagMessage, setConfirmationModalDuplicatedTagMessage] = useState('')
  const [formData, setFormData] = useState<any[]>([])

  useEffect(() => {
    dispatch(getLot({ args: { feedlotId } }))
  }, [lotId, feedlotId, dispatch])

  const buttonLabel = selectedAnimal?.id ? 'save' : 'add'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const verifiesDuplicatedTag = async (data: any) => {
    setFormData(data)
    if (animals.find(a => a.tag === data.tag && a.id !== data.id) !== undefined) {
      setConfirmationModalDuplicatedTagMessage(t('confirmation.registered_animal_with_same_tag'))
      setConfirmationModalDuplicatedTagStatus(true)
    } else {
      handleSubmit(data)
    }
  }

  const handleSubmit: any = async (data: any) => {
    if (data?.newLotId) setConfirmationModalStatus(true)
    else saveAnimalData()
  }

  const callSaveAnimalData: any = async () => {
    saveAnimalData()
  }

  const saveAnimalData: any = async () => {
    let animal: any = formData
    animal.id = selectedAnimal?.id
    animal.breedId = animal.breed?.id
    animal.coatId = animal.coat?.id
    animal.genderId = animal.gender?.id
    if (selectedAnimal?.id) animal.newLotId = animal?.lot?.id !== selectedAnimal?.lotId ? animal?.lot?.id : null
    animal.eccHandling = animal.handling?.ecc
    if (!selectedAnimal?.id) animal.feedlotId = feedlotId

    delete animal.handling
    delete animal.lot

    // const additionalInfo: any[] = []

    // for (let i = 0; i < animal.additionalInfo?.length; i++) {
    //   for (const key in animal.additionalInfo[i]?.info) {
    //     let template = null
    //     if (animalAdditionalInfoTemplates) template = animalAdditionalInfoTemplates.find(t => t.description === key)
    //     if (template && animal.additionalInfo[i]?.info[key] !== '') {
    //       const info: any = {}
    //       info['value'] = animal.additionalInfo[i]?.info[key]
    //       info['id'] = template.id
    //       additionalInfo.push(info)
    //     }
    //   }
    // }
    animal = formatFormFields(animal)

    try {
      if (!lotId) return
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        earring: Yup.string().required(() => t('field.required')),
        breed: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        }),
        coat: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        }),
        gender: Yup.object().shape({
          id: Yup.number().required(() => t('field.required'))
        })
      })

      await schema.validate(animal, { abortEarly: false })
      // animal.additionalInfo = additionalInfo
      dispatch(saveAnimal({ lotId, animal: [animal] }))
      setConfirmationModalStatus(false)
      handleClose()
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  const setSelectedLotId = (id: number) => {
    dispatch(setSelectedLot(id))
  }

  // let count_1 = -1
  // let count_2: number = selectedAnimal?.additionalInfo?.length ? selectedAnimal?.additionalInfo?.length + 1 : -1

  return (
    <div>
      <ConfirmationModal
        text={t('confirmation.save_animals_file')}
        handleConfirm={() => callSaveAnimalData()}
        handleCancel={() => setConfirmationModalStatus(false)}
        open={isConfirmationModalOpen}
        title='Attention'
      ></ConfirmationModal>
      <ConfirmationModal
        text={confirmationModalDuplicatedTagMessage}
        handleConfirm={() => handleSubmit()}
        handleCancel={() => setConfirmationModalDuplicatedTagStatus(false)}
        open={isConfirmationModalDuplicatedTagOpen}
        title='Attention'
      ></ConfirmationModal>
      <div className='container'>
        {animalLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} initialData={selectedAnimal} onSubmit={verifiesDuplicatedTag}>
            <div className='row'>
              <div className='col-3'>
                <Input name='earring' label={t('field.earring')} onFocus={clearError} />
              </div>
              <div className='col-2'>
                <Select
                  name='active'
                  label={t('default.active')}
                  defaultOptions={statusOptions}
                  selectedValue={!selectedAnimal || selectedAnimal?.active === true ? 1 : 0}
                  onFocus={clearError}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='tag' label={t('field.tag')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <BreedSelector
                  selected={selectedAnimal?.breed?.id}
                  cleanError={() => formRef.current?.setFieldError('breed.id', '')}
                />
              </div>
              <div className='col-3'>
                <CoatSelector
                  selected={selectedAnimal?.coat?.id}
                  cleanError={() => formRef.current?.setFieldError('coat.id', '')}
                />
              </div>
              <div className='col-3'>
                <GenderSelector
                  selected={selectedAnimal?.gender?.id}
                  cleanError={() => formRef.current?.setFieldError('gender.id', '')}
                />
              </div>
            </div>
            {selectedAnimal?.lotId ? (
              <div className='row'>
                <div className='col-3'>
                  <LotSelector
                    selected={selectedAnimal?.lotId}
                    feedlotId={feedlotId}
                    cleanError={() => formRef.current?.setFieldError('selectedAnimal.newLotId', '')}
                    handleSelect={(id: number) => setSelectedLotId(id)}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className='row'>
              <div className='col-3'>
                <Input name='initialWeight' label={t('field.initialWeight')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='age' label={t('field.age')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='horn' label={t('field.horn')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='sisbov' label={t('field.sisbov')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='handling.ecc' label={t('field.initialEcc')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <DatePicker name='endDate' label={t('field.endDate')} onFocus={clearError} />
              </div>
            </div>
            {/* {animalAdditionalInfoTemplateLoading ? (
              <Loading />
            ) : (
              _.map(animalAdditionalInfoTemplates, t => {
                let count = -1
                let aux = 0
                count_1++
                if (selectedAnimal?.additionalInfo) {
                  return _.map(selectedAnimal?.additionalInfo, ai => {
                    count++
                    if (t.description) {
                      if (
                        selectedAnimal?.additionalInfo[count]?.info &&
                        selectedAnimal?.additionalInfo[count]?.info[t?.description]
                      ) {
                        aux++
                        return (
                          <Input
                            key={count_1.toString()}
                            name={`additionalInfo${'[' + count + ']'}.info.${t.description}`}
                            onFocus={clearError}
                            label={t?.description || ''}
                          />
                        )
                      } else if (
                        selectedAnimal?.additionalInfo &&
                        aux === 0 &&
                        count === selectedAnimal?.additionalInfo?.length - 1
                      ) {
                        count_2++
                        aux++
                        return (
                          <Input
                            key={count_1.toString()}
                            name={`additionalInfo${'[' + count_2 + ']'}.info.${t.description}`}
                            onFocus={clearError}
                            label={t?.description || ''}
                          />
                        )
                      }
                    }
                  })
                } else {
                  return (
                    <Input
                      key={count_1.toString()}
                      name={`additionalInfo${'[' + count_1 + ']'}.info.${t.description}`}
                      onFocus={clearError}
                      label={t?.description || ''}
                    />
                  )
                }
              })
            )} */}
            <div className='row'>
              <div className='col-4 col-offset-4 mtop-5'>
                <Button label={buttonLabel} />
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default AnimalForm
