import React from 'react'
import { Props } from './types'

import Modal from 'components/widgets/Modal'
import AnimalFileList from 'components/lists/AnimalFileList'
import './styles.scss'

const AnimalFileModal: React.FC<Props> = ({ isOpen, lotId, feedlotId, handleClose }) => {
  const close = () => {
    handleClose()
  }

  return (
    <Modal handleClose={handleClose} open={isOpen} title={'file.import_animals_file'}>
      <AnimalFileList lotId={lotId || 0} feedlotId={feedlotId} setModalStatus={close} />
    </Modal>
  )
}

export default AnimalFileModal
