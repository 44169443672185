import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { ApplicationState } from 'store/types'
import { Column } from 'components/widgets/Table/types'
import { getAnimal } from 'store/animal/actions'
import { Props } from './types'
import { Dispatch } from 'redux'
import _ from 'lodash'
import { generalRender, getTitle } from './helper'
import { useTranslation } from 'react-i18next'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import MaterialTable from 'material-table'
import './styles.scss'
import { saveLot } from 'store/feedlot/actions'
import { LotUpdate } from 'services/lot/types'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'

const AnimalMonitoringList: React.FC<Props> = ({ handleClose }) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const { animalLoading, animals } = useSelector((state: ApplicationState) => state.animal)
  const { selectedLot } = useSelector((state: ApplicationState) => state.feedlot)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    if (selectedLot && animals[0]?.lotId !== selectedLot.id) {
      dispatch(
        getAnimal({
          args: { lotId: selectedLot?.id },
          fields: {
            id: true,
            lotId: true,
            earring: true,
            age: true,
            tag: true,
            horn: true,
            initialWeight: true,
            finalWeight: true,
            active: true,
            endDate: true,
            note: true,
            sisbov: true,
            lastWeighingDate: true,
            ecc: true,
            delayedWeighingDays: true,
            gender: {
              id: true,
              code: true,
              description: true
            },
            coat: {
              id: true,
              code: true,
              description: true
            },
            breed: {
              id: true,
              code: true,
              description: true
            },
            additionalField: true,
            handling: {
              handlingId: true,
              animalId: true,
              ecc: true,
              entrySequence: true,
              observation: true
            }
          }
        })
      )
    }
  }, [dispatch, selectedLot])

  const columnNames = [
    'active',
    'earring',
    'tag',
    'breed.description',
    'coat.description',
    'gender.description',
    'initialWeight',
    'age',
    'horn',
    'sisbov',
    'lastWeighingDate',
    'delayedWeighingDays',
    'handling.ecc'
  ]

  const columns: Column[] = []
  if (animals && animals.length !== 0) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }

  const validateLot = () => {
    if (selectedLot?.id) {
      const data: LotUpdate = {
        id: selectedLot?.id,
        registerStatus: !selectedLot?.registerStatus
      }
      dispatch(saveLot({ lot: data }))
    }
  }

  console.log('selectedLot?.registerStatus', selectedLot?.registerStatus)

  return (
    <div className='animal-list'>
      <MaterialTable
        isLoading={animalLoading}
        columns={columns}
        data={animals}
        style={{ width: 'auto' }}
        options={{
          header: !!animals.length,
          toolbar: true,
          // paging: !!animals.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          // selection: true,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold', width: 100 },
          pageSize: 20,
          pageSizeOptions: animals.length < 5 ? [] : [10, 20, 30, 40]
        }}
        actions={[
          {
            icon: selectedLot?.registerStatus ? 'check' : 'close',
            tooltip: selectedLot?.registerStatus ? t('lot.validated_lot') : t('lot.lot_to_validate'),
            onClick: (_: any, data: any) => null,
            isFreeAction: true
          }
        ]}
        components={{
          Action: (props: {
            action: {
              tooltip: string
              icon: string | Record<string, any> | null | undefined
              onClick: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>, arg1: any) => void
            }
            data: any
          }) => {
            if (props.action.icon === 'check' || props.action.icon === 'close') {
              return (
                <Tooltip title={t('lot.validate_lot') ?? ''}>
                  <button
                    className='validate-button'
                    onClick={() => validateLot()}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    style={{
                      color: selectedLot?.registerStatus ? 'rgb(76, 165, 3)' : '#FF4400',
                      border: selectedLot?.registerStatus ? '1px solid rgb(76, 165, 3)' : '1px solid #FF4400',
                      boxShadow: isHovering
                        ? selectedLot?.registerStatus
                          ? '0px 0px rgb(76, 165, 3)'
                          : '0px 0px #FF4400'
                        : selectedLot?.registerStatus
                        ? '2px 3px rgb(76, 165, 3)'
                        : '2px 3px #FF4400'
                    }}
                  >
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      {selectedLot?.registerStatus ? (
                        <CheckOutlined
                          style={{ color: selectedLot?.registerStatus ? 'rgb(76, 165, 3)' : '#FF4400' }}
                        ></CheckOutlined>
                      ) : (
                        <CloseOutlined
                          style={{ color: selectedLot?.registerStatus ? 'rgb(76, 165, 3)' : '#FF4400' }}
                        ></CloseOutlined>
                      )}
                      {selectedLot?.registerStatus ? t('lot.validated_lot') : t('lot.lot_to_validate')}
                    </span>
                  </button>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title={props.action.tooltip}>
                  <IconButton
                    // aria-label={props.action?.icon}
                    size='small'
                    onClick={(event: any) => props.action.onClick(event, props.data)}
                  >
                    <Icon>{props.action.icon}</Icon>
                  </IconButton>
                </Tooltip>
              )
            }
          },
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </div>
  )
}

export default AnimalMonitoringList
