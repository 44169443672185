import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApplicationState } from 'store/types'
import { Column } from 'components/widgets/Table/types'
import { getAnimal, setSelectedAnimal, setSelectedAnimalFile, setSelectedAnimalGroup } from 'store/animal/actions'
import { Props } from './types'
import { Dispatch } from 'redux'
import _ from 'lodash'
import { generalRender, getTitle, verifyFileColumns } from './helper'
import { useTranslation } from 'react-i18next'
import AnimalModal from '../../modals/AnimalModal'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import AnimalFileModal from 'components/modals/AnimalFileModal'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { ExportToCsv } from 'export-to-csv'
import { showToast } from 'store/main/actions'
import AnimalGroupModal from 'components/modals/AnimalGroupModal'
import MaterialTable from 'material-table'
import './styles.scss'
import { saveLot } from 'store/feedlot/actions'
import { lotCreate } from 'services/lot'
import { Lot } from 'store/feedlot/types'
import { LotUpdate } from 'services/lot/types'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'
import { mdiArmFlex } from '@mdi/js'

const AnimalList: React.FC<Props> = ({ feedlotId, penId, lotId, registerStatus }) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const { selectedLot, lotLoading } = useSelector((state: ApplicationState) => state.feedlot)
  const { animalLoading, animals } = useSelector((state: ApplicationState) => state.animal)
  const [isAnimalModalOpen, setAnimalModalStatus] = useState<boolean>(false)
  const [isAnimalGroupModalOpen, setAnimalGroupModalStatus] = useState<boolean>(false)
  const [isFileModalOpen, setFileModalStatus] = useState<boolean>(false)
  const [isNew, setIsNew] = useState<boolean>(true)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    dispatch(
      getAnimal({
        args: { lotId },
        fields: {
          id: true,
          lotId: true,
          earring: true,
          age: true,
          tag: true,
          horn: true,
          initialWeight: true,
          finalWeight: true,
          active: true,
          endDate: true,
          note: true,
          sisbov: true,
          lastWeighingDate: true,
          ecc: true,
          delayedWeighingDays: true,
          gender: {
            id: true,
            code: true,
            description: true
          },
          coat: {
            id: true,
            code: true,
            description: true
          },
          breed: {
            id: true,
            code: true,
            description: true
          },
          additionalField: true,
          handling: {
            handlingId: true,
            animalId: true,
            ecc: true,
            entrySequence: true,
            observation: true
          }
        }
      })
    )
  }, [dispatch, lotId])

  const editAction = (selectedAnimal: any) => {
    if (selectedAnimal.id) setIsNew(false)
    dispatch(setSelectedAnimal(selectedAnimal.id))
    setAnimalModalStatus(true)
  }

  const columnNames = [
    'active',
    'earring',
    'tag',
    'endDate',
    'breed.description',
    'coat.description',
    'gender.description',
    'initialWeight',
    'age',
    'horn',
    'sisbov',
    'handling.ecc'
  ]

  const animalsModelFileOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    // title: 'Animals Model File',
    useTextFile: false,
    useBom: true,
    filename: 'Animals_Model_File',
    // useKeysAsHeaders: true
    headers: ['earring', 'tag', 'breed', 'coat', 'gender', 'initialWeight', 'age', 'horn', 'sisbov', 'initialEcc'] // Won't work with useKeysAsHeaders present!
  }

  const animalsModelFileData: Record<string, any>[] = [{}]

  const downloadAnimalsModelFile = () => {
    const csvExporter = new ExportToCsv(animalsModelFileOptions)
    csvExporter.generateCsv(animalsModelFileData)
  }

  // _.map(animalAdditionalInfoTemplates, t => {
  //   if (t.description !== undefined) {
  //     animalsModelFileOptions.headers.push('additionalInfo.' + t.description)
  //   }
  // })

  // const additionalInfoColumns: string[] = []
  // _.map(animals, a => {
  //   if (a.additionalInfo) {
  //     _.map(a.additionalInfo, add => {
  //       for (const key in add.info) {
  //         if (key !== 'id') {
  //           additionalInfoColumns.push('additionalInfo' + key)
  //         }
  //       }
  //     })
  //   }
  // })

  // let count = 0

  // _.map(_.uniq(additionalInfoColumns), c => {
  //   if (count <= 2) {
  //     columnNames.push(c)
  //   }
  //   count++
  // })

  const columns: Column[] = []
  if (animals && animals.length !== 0) {
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
  }

  const importFile = (file: any, fileInfo?: IFileInfo) => {
    if (!verifyFileColumns(file[0], animalsModelFileOptions.headers)) {
      dispatch(showToast(t('notice.animals_import_file')))
      return
    }
    for (let i = 1; i < file.length; i++) {
      if (file[i].length === 1 && file[i][0] === '') {
        file.splice(i, 1)
      }
    }
    dispatch(setSelectedAnimalFile(file))
    setFileModalStatus(true)
  }

  const groupEditAction = (animals: any) => {
    dispatch(setSelectedAnimalGroup(animals))
    setAnimalGroupModalStatus(true)
  }

  return (
    <div className='animal-list'>
      <AnimalModal
        isOpen={isAnimalModalOpen}
        handleClose={() => setAnimalModalStatus(false)}
        feedlotId={feedlotId}
        lotId={lotId}
        isNew={isNew}
      />
      <AnimalGroupModal
        isOpen={isAnimalGroupModalOpen}
        handleClose={() => setAnimalGroupModalStatus(false)}
        feedlotId={feedlotId}
        lotId={lotId}
      />
      <AnimalFileModal
        isOpen={isFileModalOpen}
        handleClose={() => setFileModalStatus(false)}
        lotId={lotId}
        feedlotId={feedlotId}
      />
      <MaterialTable
        isLoading={animalLoading || lotLoading}
        columns={columns}
        data={animals}
        style={{ width: 'auto' }}
        options={{
          header: !!animals.length,
          toolbar: true,
          // paging: !!animals.length,
          toolbarButtonAlignment: 'left',
          searchFieldAlignment: 'left',
          showTitle: false,
          emptyRowsWhenPaging: false,
          selection: true,
          addRowPosition: 'first',
          headerStyle: { fontSize: 16, fontWeight: 'bold', width: 100 },
          pageSizeOptions: animals.length < 5 ? [] : animals.length < 10 ? [5, 10] : [5, 10, 20]
        }}
        actions={[
          {
            icon: 'add',
            tooltip: t('animal.new'),
            onClick: (_: any, data: any) => editAction(data),
            isFreeAction: true
          },
          {
            icon: 'text_snippet',
            tooltip: t('file.animals_model_file'),
            onClick: (_: any, data: any) => downloadAnimalsModelFile(),
            isFreeAction: true
          },
          {
            icon: 'import',
            tooltip: t('file.import_csv'),
            onClick: (_: any, data: any) => null,
            isFreeAction: true
          },
          { icon: 'edit', position: 'row', tooltip: t('table.edit'), onClick: (_: any, data: any) => editAction(data) },
          {
            icon: 'edit',
            position: 'toolbarOnSelect',
            tooltip: t('table.edit'),
            onClick: (_: any, data: any) => groupEditAction(data)
          }
        ]}
        components={{
          Action: (props: {
            action: {
              tooltip: string
              icon: string | Record<string, any> | null | undefined
              onClick: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>, arg1: any) => void
            }
            data: any
          }) => {
            if (props.action.icon === 'import') {
              return (
                <Tooltip title={t('notice.animals_import_file') ?? ''}>
                  <button className='download-button'>
                    <CSVReader
                      label={t('animal.import_animals')}
                      inputStyle={{ opacity: 0, width: '5%' }}
                      onFileLoaded={(data, fileInfo) => importFile(data, fileInfo)}
                    ></CSVReader>
                  </button>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title={props.action.tooltip}>
                  <IconButton
                    // aria-label={props.action?.icon}
                    size='small'
                    onClick={(event: any) => props.action.onClick(event, props.data)}
                  >
                    <Icon>{props.action.icon}</Icon>
                  </IconButton>
                </Tooltip>
              )
            }
          },
          Container: (props: {
            children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
          }) => <div style={{ width: 'auto' }}>{props.children}</div>
        }}
      />
    </div>
  )
}

export default withRouter(AnimalList)
