import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { Column } from 'components/widgets/Table/types'
import { Props } from './types'
import _ from 'lodash'
import { generalRender, getTitle, formatData, formatDataToSave } from './helper'
import { useTranslation } from 'react-i18next'
import { saveAnimal, getBreed, getCoat, getGender } from 'store/animal/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import { BreedQueryFields, CoatQueryFields, GenderQueryFields } from 'services/animal/types'
import Loading from 'components/widgets/Loading'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { Animal } from 'store/animal/types'
import MaterialTable from 'material-table'

const AnimalFileList: React.FC<Props> = ({ lotId, feedlotId, setModalStatus }) => {
  const [isFileModalOpen, setFileModalStatus] = useState<boolean>(false)
  const { selectedPen } = useSelector((state: ApplicationState) => state.feedlot)
  const { animals, selectedAnimalFile, breeds, coats, genders } = useSelector((state: ApplicationState) => state.animal)
  const [isConfirmationModalDuplicatedTagOpen, setConfirmationModalDuplicatedTagStatus] = useState<boolean>(false)
  const [confirmationModalDuplicatedTagMessage, setConfirmationModalDuplicatedTagMessage] = useState('')

  const dispatch: Dispatch = useDispatch()
  let loading = true

  useEffect(() => {
    if (!breeds?.length) {
      const fields: BreedQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getBreed({ fields }))
    }
    if (!coats?.length) {
      const fields: CoatQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getCoat({ fields }))
    }
    if (!genders?.length) {
      const fields: GenderQueryFields = {
        id: true,
        code: true,
        description: true
      }
      dispatch(getGender({ fields }))
    }
  }, [breeds, coats, genders, lotId, dispatch])

  let animalsData: any = []

  const columnNames = [
    'active',
    'earring',
    'tag',
    'breed',
    'coat',
    'gender',
    'initialWeight',
    'age',
    'horn',
    'sisbov',
    'initialEcc'
  ]

  // _.map(animalAdditionalInfoTemplates, t => {
  //   if (t.description !== undefined) {
  //     columnNames.push('additionalInfo.' + t.description)
  //   }
  // })

  const columns: Column[] = []

  if (selectedAnimalFile && breeds !== undefined && coats !== undefined && genders !== undefined) {
    for (const key in selectedAnimalFile) {
      if (key !== '0') {
        const animal: any = {}
        for (let j = 0; j < selectedAnimalFile[key].length + 1; j++) {
          if (columnNames[j] === 'active') animal[columnNames[j]] = true
          else animal[columnNames[j]] = selectedAnimalFile[key][j - 1]
        }
        animalsData.push(animal)
      }
    }

    animalsData = formatData(animalsData, breeds, coats, genders, isFileModalOpen)
    _.map(columnNames, (column: string) => {
      columns.push({
        title: getTitle(column),
        field: column,
        render: (rowData: Record<string, any>) => generalRender(rowData, column),
        cellStyle: { width: 10 }
      })
    })
    loading = false
  }

  const editAction = () => {
    const animalFilteredTag = _.uniqBy(animalsData, 'tag')
    let repeatedTag = false
    animalsData.map((ad: Animal) => {
      if (animals.find(a => a.tag === ad.tag) !== undefined) repeatedTag = true
      return true
    })

    // Verifies if there are repeated tags in file
    if (animalFilteredTag.length !== animalsData.length) {
      setConfirmationModalDuplicatedTagMessage(t('confirmation.animal_data_duplicated_tag'))
      setConfirmationModalDuplicatedTagStatus(true)

      // Verifies if there are repeated comparing with animals registered in database
    } else if (repeatedTag) {
      setConfirmationModalDuplicatedTagMessage(t('confirmation.registered_animal_with_same_tag'))
      setConfirmationModalDuplicatedTagStatus(true)
    } else {
      setFileModalStatus(true)
    }
  }

  const saveAnimalData = () => {
    loading = true
    setModalStatus()
    const data = formatDataToSave(animalsData, feedlotId, selectedPen?.id, lotId)
    dispatch(saveAnimal({ lotId, animal: data }))
    setFileModalStatus(false)
    loading = false
  }
  const { t } = useTranslation()
  return (
    <div>
      <ConfirmationModal
        text={t('confirmation.save_animals_file')}
        handleConfirm={() => saveAnimalData()}
        handleCancel={() => setFileModalStatus(false)}
        open={isFileModalOpen}
        title={t('notice.attention')}
      ></ConfirmationModal>
      <ConfirmationModal
        text={confirmationModalDuplicatedTagMessage}
        handleConfirm={() => setFileModalStatus(true)}
        handleCancel={() => setConfirmationModalDuplicatedTagStatus(false)}
        open={isConfirmationModalDuplicatedTagOpen}
        title='Attention'
      ></ConfirmationModal>
      {loading ? (
        <Loading />
      ) : (
        <MaterialTable
          columns={columns}
          data={animalsData}
          style={{ width: 'auto' }}
          options={{
            header: !!animalsData.length,
            toolbar: !!animalsData.length,
            // paging: !!animalsData.length,
            toolbarButtonAlignment: 'left',
            searchFieldAlignment: 'left',
            showTitle: false,
            emptyRowsWhenPaging: false,
            addRowPosition: 'first',
            headerStyle: { fontSize: 16, fontWeight: 'bold' },
            pageSizeOptions: animalsData.length < 5 ? [] : animalsData.length < 10 ? [5, 10] : [5, 10, 20]
          }}
          actions={[
            {
              icon: 'save',
              tooltip: t('button.save_file'),
              onClick: (_: any, data: any) => editAction(),
              isFreeAction: true
            }
          ]}
          components={{
            Container: (props: {
              children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
            }) => <div style={{ width: 'auto' }}>{props?.children}</div>
          }}
        />
      )}
    </div>
  )
}

export default withRouter(AnimalFileList)
