import { Props } from './types'
import Loading from 'components/widgets/Loading'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'
import DatePicker from 'components/widgets/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types'
import Button from 'components/widgets/Button'
import * as Yup from 'yup'
import _ from 'lodash'
import { getLot, setSelectedLot } from 'store/feedlot/actions'
import { saveAnimal } from 'store/animal/actions'
import { statusOptions } from 'data'
import Select from 'components/widgets/Select'
import BreedSelector from 'components/selectors/Breed'
import CoatSelector from 'components/selectors/Coat'
import GenderSelector from 'components/selectors/Gender'
import { useTranslation } from 'react-i18next'
import LotSelector from 'components/selectors/Lot'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { AnimalUpdate } from 'services/animal/types'
import moment from 'moment'

const AnimalGroupForm: React.FC<Props> = ({ feedlotId, lotId, handleClose }) => {
  const { t } = useTranslation()
  const formRef: any = useRef(null)
  const dispatch = useDispatch()
  const { selectedAnimalGroup, animalGroupLoading } = useSelector((state: ApplicationState) => state.animal)
  const [isConfirmationModalOpen, setConfirmationModalStatus] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(null)

  useEffect(() => {
    dispatch(getLot({ args: { feedlotId } }))
  }, [feedlotId, dispatch])

  const buttonLabel = 'save'

  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')

  const handleSubmit: any = (data: any) => {
    setFormData(data)
    setConfirmationModalStatus(true)
  }

  const callSaveAnimalGroupData: any = () => {
    saveAnimalData(formData)
  }

  const saveAnimalData: any = async (data: any) => {
    const animalsGroup: any = selectedAnimalGroup
    const animals: any = []

    _.map(animalsGroup, a => {
      const animal: AnimalUpdate = {
        id: a.id,
        earring: a.earring,
        tag: a.tag,
        lastWeightDate: a.lastWeightDate,
        endDate: data.endDate ? new Date(moment(data.endDate).format()) : undefined,
        finalWeight: a.finalWeight,
        newLotId: data.lot.id ? data.lot.id : undefined,
        horn: a.horn,
        initialWeight: a.initialWeight,
        note: a.note,
        sisbov: a.sisbov,
        active: data.active ? true : false,
        age: data.age ? Number(data.age) : a.age,
        ecc: data.ecc ? Number(data.ecc) : a.handling.ecc,
        eccHandling: data.eccHandling ? Number(data.eccHandling) : a.handling.ecc,
        breedId: data.breed.id ? data.breed.id : a.breed.id,
        coatId: data.coat.id ? data.coat.id : a.coat.id,
        genderId: data.gender.id ? data.gender.id : a.gender.id
      }

      animals.push(animal)
    })

    _.map(Object.entries(animals), ([key, value]) => (animals[key] = value === '' ? null : value))

    try {
      if (!lotId) return
      formRef.current.setErrors({})
      dispatch(saveAnimal({ lotId, animal: animals }))
      setConfirmationModalStatus(false)
      handleClose()
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  const handleCancel: any = () => {
    handleClose()
  }

  const setSelectedLotId = (id: number) => {
    dispatch(setSelectedLot(id))
  }

  return (
    <div>
      <ConfirmationModal
        text={t('confirmation.update_animal_group')}
        handleConfirm={() => callSaveAnimalGroupData()}
        handleCancel={() => setConfirmationModalStatus(false)}
        open={isConfirmationModalOpen}
        title='Attention'
      ></ConfirmationModal>
      <div className='container'>
        {animalGroupLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-3'>
                <LotSelector
                  feedlotId={feedlotId}
                  cleanError={() => formRef.current?.setFieldError('selectedAnimalGroup.newLotId', '')}
                  handleSelect={(id: number) => setSelectedLotId(id)}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Select
                  name='active'
                  label={t('default.active')}
                  defaultOptions={statusOptions}
                  onFocus={clearError}
                  selectedValue={1}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <BreedSelector cleanError={() => formRef.current?.setFieldError('breed.id', '')} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <CoatSelector cleanError={() => formRef.current?.setFieldError('coat.id', '')} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <GenderSelector cleanError={() => formRef.current?.setFieldError('gender.id', '')} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='age' label={t('field.age')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Input name='eccHandling' label={t('field.initialEcc')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <DatePicker name='endDate' label={t('field.endDate')} onFocus={clearError} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mtop-3'>
                <Button onClick={handleCancel} autoFocus label={'button.cancel'} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Button label={buttonLabel} type='submit' />
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default AnimalGroupForm
