import { action } from 'typesafe-actions'
import { AnimalTypes, AnimalGroup, DisableAnimalType } from './types'
import {
  AnimalQueryParams,
  AnimalMutationArgs,
  BreedQueryParams,
  CoatQueryParams,
  GenderQueryParams
} from 'services/animal/types'

export const getAnimal = (params?: AnimalQueryParams) => {
  return action(AnimalTypes.ACTION_GET_ANIMAL, { params })
}
export const setSelectedAnimal = (animalId?: number) => action(AnimalTypes.ACTION_SET_SELECTED_ANIMAL, { animalId })

export const setSelectedAnimalGroup = (animals?: AnimalGroup[]) =>
  action(AnimalTypes.ACTION_SET_SELECTED_ANIMAL_GROUP, { animals })

export const setSelectedAnimalFile = (animals?: any[]) =>
  action(AnimalTypes.ACTION_SET_SELECTED_ANIMAL_FILE, { animals })
export const saveAnimal = (args: AnimalMutationArgs) => action(AnimalTypes.ACTION_SAVE_ANIMAL, { args })

export const getBreed = (params?: BreedQueryParams) => action(AnimalTypes.ACTION_GET_BREED, { params })
export const setSelectedBreed = (breedId?: number) => action(AnimalTypes.ACTION_SET_SELECTED_BREED, { breedId })

export const getCoat = (params?: CoatQueryParams) => action(AnimalTypes.ACTION_GET_COAT, { params })
export const setSelectedCoat = (coatId?: number) => action(AnimalTypes.ACTION_SET_SELECTED_COAT, { coatId })

export const getGender = (params?: GenderQueryParams) => action(AnimalTypes.ACTION_GET_GENDER, { params })
export const setSelectedGender = (genderId?: number) => action(AnimalTypes.ACTION_SET_SELECTED_GENDER, { genderId })

export const disableAnimals = (ids?: Array<DisableAnimalType>) => action(AnimalTypes.ACTION_DISABLE_ANIMAL, { ids })
